import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { WebappLoaderModule } from './app/webapp-loader.module';
import { environment } from './environments/environment';
import { FEATURE_TOGGLE_SESSION_KEY } from '@farmnet/fnx-components';

if (environment.production) {
  enableProdMode();
}

const value = sessionStorage.getItem(FEATURE_TOGGLE_SESSION_KEY);
const toggles = value ? JSON.parse(value) : [];
if (!toggles.find((t) => t === 'teamfrontend.webapp-loader.disable-userlane')) {
  toggles.push('teamfrontend.webapp-loader.disable-userlane');
}
sessionStorage.setItem(FEATURE_TOGGLE_SESSION_KEY, JSON.stringify(toggles));

platformBrowserDynamic()
  .bootstrapModule(WebappLoaderModule)
  .catch((err) => console.error(err));
